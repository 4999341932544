export default {
  h1: {
    fontWeight: 700,
    fontSize: 70,
    fontFamily: "SF Pro Display, sans-serif",
    "@media(max-width:767px)": {
      fontSize: 38,
    },

  },
  h2: {
    fontWeight: "bolder",
    marginBottom: "25px",
    fontSize: 47,
    fontFamily: "SF Pro Display, sans-serif",
    "@media(max-width:767px)": {
      fontSize: 30,
    },

  },
  h3: {
    fontWeight: 600,
    fontSize: 23,
    margin: "23px",
    fontFamily: "SF Pro Display, sans-serif",
    "@media(max-width:767px)": {
      fontSize: 30,
    },
  },
  h4: {
    fontWeight: 500,
    fontSize: 20,
    color: "white",
    paddingTop: 23,
  },
  h5: {
    fontWeight: 500,
    fontSize: 14,
    color: "rgba(255, 255, 255, 0.7)",
  },
  h6: {
    fontWeight: 500,
    fontSize: 30,
    fontFamily: "SF Pro Display, sans-serif",
    "@media(max-width:605px)": {
      fontSize: "16px !important",
    },

  },
  overline: {
    fontWeight: 500,
  },
  button: {
    textTransform: "capitalize",
    borderRadius: 27,
  },
  body1: {
    fontSize: "12px",
    fontFamily: "SF Pro Display, sans-serif",
    fontWeight: "300",
    lineHeight: "25px",
    "@media(max-width:605px)": {
      fontSize: "14px !important",
    },

  },
  body2: {
    fontSize: "18px",
    marginBottom: "28px",
    color: "rgba(0, 0, 0, 0.6)",
    fontFamily: "SF Pro Display, sans-serif",
    fontWeight: "400",
    lineHeight: "25px",
    "@media(max-width:605px)": {
      fontSize: "14px !important",
    },

  },
  body3: {
    fontSize: "15px",
    marginBottom: "28px",
    color: "rgba(255, 255, 255, 0.6)",
    fontFamily: "SF Pro Display, sans-serif",
    fontWeight: "550",
    lineHeight: "25px",
    "@media(max-width:605px)": {
      fontSize: "14px !important",
    },

  },
  // body4: {
  //   fontSize: "15px",
  //   marginBottom: "28px",
  //   color: "rgba(255, 255, 255, 0.6)",
  //   fontFamily: "SF Pro Display, sans-serif",
  //   fontWeight: "550",
  //   lineHeight: "25px",
  //   "@media(max-width:605px)": {
  //     fontSize: "12px !important",
  //   },

  // },
};
