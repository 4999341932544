import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/index")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/privacy")),
  },
  {
    exact: true,
    path: "/terms",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Term")),
  },
  {
    exact: true,
    path: "/aboutus",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/VD Exchange/Index")),
  },
  {
    exact: true,
    path: "/workwithus",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/VDToken/Index")),
  },
  {
    exact: true,
    path: "/vd-exchange",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/VD Exchange/Index")),
  },
  {
    path: "/cloudsoftware",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/VDWallet/Index")),
  },
  {
    exact: true,
    path: "/partnerwithus",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/VDBank/Index")),
  },
  {
    exact: true,
    path: "/contact",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/ContactUs/Index")),
  },
  {
    exact: true,
    path: "/oursolution",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/OurSolution/index")),
  },
  {
    exact: true,
    path: "/ourproducts",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/OurProduct/Index")),
  },
  {
    exact: true,
    path: "/zonebasedleakdetection",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/ZoneDetail/index")),
  },
  {
    exact: true,
    path: "/zonebasedleakdetection/leakdetect",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/ZoneDetail2/Index")),
  },

  
  {
    component: () => <Redirect to="/404" />,
  },
];
